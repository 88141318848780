import React from "react";
import PageMeta from "../../components/common/PageMeta";
import OnboardingForm from "./OnboardingForm";
import OnboardingHeader from "./OnboardingHeader";
import Footer from "../../layout/Footer/Footer";
import Navbar from "../../layout/Header/Navbar";
import Layout from "../../layout/Layout";

const Onboarding = () => {
  return (
    <Layout>
      <PageMeta title="Onboarding" />
      <Navbar posAbsolute />
      <OnboardingHeader />
      <section className="ptb-120">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <OnboardingForm />
          </div>
        </div>
      </section>
      <Footer />
    </Layout>
  );
};
export default Onboarding;
