import React from 'react';
import { Link } from 'react-router-dom';
import { url } from '../../api/config';

const Footer = () => {
  return (
    <>
      <footer className='footer-section'>
        <div className="footer-top bg-dark bg-gradient text-white ptb-100">
          <div className='container'>
            <div className='row justify-content-between'>
              <div className='col-md-8 col-lg-4 mb-md-4 mb-lg-0'>
                <div>
                  <div className='footer-single-col mb-4'>
                    <img
                      src='assets/img/iregisterkids-white.png'
                      alt='logo'
                      className='img-fluid logo-white'
                    />
                    <img
                      src='assets/img/iregisterkids.png'
                      alt='logo'
                      className='img-fluid logo-color'
                    />
                  </div>
                  <p>
                    Secured childcare...
                  </p>
                  <h6>Office Address</h6>
                  <p>6137 Crawfordsville Rd Suite F, PMB #361 Speedway, Indiana 46224 USA.</p>
                  
                  <ul className='list-unstyled footer-nav-list'>
                    <li>
                      
                  <h6>Office Phone</h6>
                  <a  href="tel:+13174562893" className="remove-underscore">+1 (317)-456-2893</a> 
                    </li>
                 
                    <li>
                  <h6>Email Address</h6>
                  <a href="mailto:info@iregisterkids.com" className="remove-underscore">info@iregisterkids.com</a>
                  </li>

                  </ul>
                </div>
              </div>
              <div className='col-md-12 col-lg-7 mt-4 mt-md-0 mt-lg-0'>
                <div className='row'>
                  <div className='col-md-4 col-lg-4 mt-4 mt-md-0 mt-lg-0'>
                    <div className='footer-single-col'>
                      <h3>Quick Links</h3>
                      <ul className='list-unstyled footer-nav-list mb-lg-0'>
                        <li>
                          <Link to='/terms-of-use' className='text-decoration-none'>
                          Terms of Use
                          </Link>
                        </li>
                        <li>
                          <Link to='privacy-policy' className='text-decoration-none'>
                          Privacy Policy
                          </Link>
                        </li>
                        <li>
                          <Link to='/pricing' className='text-decoration-none'>
                            Pricing
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className='col-md-4 col-lg-4 mt-4 mt-md-0 mt-lg-0'>
                    <div className='footer-single-col'>
                      <h3>Company
                      </h3>
                      <ul className='list-unstyled footer-nav-list mb-lg-0'>
                      <li>
                          <Link to='/about-us' className='text-decoration-none'>
                            About Us
                          </Link>
                        </li>
                        <li>
                          <Link to='/contact-us' className='text-decoration-none'>
                            Contact Us
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className='col-md-4 col-lg-4 mt-4 mt-md-0 mt-lg-0'>
                    <div className='footer-single-col'>
                      <h3>Download</h3>
                      <a
                            href='/download' target="_blank"
                            className='text-decoration-none text-white fs-6' rel="noopener noreferrer"
                          >
                            Download our Apps
                          </a>
                      {/* <ul className='list-unstyled footer-nav-list mb-lg-0'>
                        <li>
                          <a
                            href={url.apk} target="_blank"
                            className='text-decoration-none' rel="noopener noreferrer"
                          >
                            Android App
                          </a>
                        </li>
                        <li>
                          <a
                            href={url.screen_app} target="_blank" rel="noopener noreferrer"
                            className='text-decoration-none'
                          >
                            Screen App
                          </a>
                        </li>
                        <li>
                          <a
                            href={url.kiosk} target="_blank" rel="noopener noreferrer"
                            className='text-decoration-none'
                          >
                            Windows OS App
                          </a>
                        </li>
                      </ul> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className= 'footer-bottom bg-dark'>
          <div className='container'>
            <div className='row justify-content-between align-items-center'>
              <div className='col-md-7 col-lg-7'>
                <div className='copyright-text'>
                  <p className='mb-lg-0 mb-md-0'>
                    &copy; {new Date().getFullYear()} iRegisterKids, All Rights Reserved.
                  </p>
                </div>
              </div>
              <div className='col-md-4 col-lg-4'>
                <div className='footer-single-col text-start text-lg-end text-md-end mb-2 mt-2'>
                  <ul className='list-unstyled list-inline footer-social-list'>
                    <li className='list-inline-item'>
                      <a  target="_blank" rel="noopener noreferrer" href='https://www.facebook.com/iregisterkids/'>
                        <i className='fab fa-facebook-f'></i>
                      </a>
                    </li>
                    <li className='list-inline-item'>
                      <a   target="_blank" rel="noopener noreferrer" href='https://www.instagram.com/iregisterkidss/'>
                        <i className='fab fa-instagram'></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
