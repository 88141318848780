import React from "react";
import FormItem from "../common/FormItem";
const FormItems = ({
  formik,
  isLoading,
  countryCodes,
  countries,
  states,
  cities,
  setSelectedCountry,
  setSelectedState,
}) => {
  return (
    <>
      <form
        onSubmit={formik.handleSubmit}
        className="mt-4 pt-5 pb-5 register-form"
      >
        <div className="row">
          <label className="mb-3">Organization Information</label>
          <div className="col-sm-6 mb-3">
            <FormItem error={formik.errors.organizationInfo?.name}>
              <input
                disabled={isLoading}
                type="text"
                id="name"
                name="organizationInfo.name"
                className="form-control"
                placeholder="Organization Name"
                aria-label="name"
                value={formik.values.organizationInfo.name}
                onChange={formik.handleChange}
              />
            </FormItem>
          </div>
          <div className="col-sm-6 mb-3">
            <FormItem error={formik.errors.organizationInfo?.emailAddress}>
              <input
                disabled={isLoading}
                type="text"
                id="emailAddress"
                name="organizationInfo.emailAddress"
                className="form-control"
                placeholder="Email Address"
                aria-label="emailAddress"
                value={formik.values.organizationInfo.emailAddress}
                onChange={formik.handleChange}
              />
            </FormItem>
          </div>

          {/* Phone Number and Country Code */}

          <div className="col-sm-6 mb-3">
            <FormItem error={formik.errors.organizationInfo?.phoneNumber?.code}>
              <select
                disabled={isLoading}
                value={formik.values.organizationInfo.phoneNumber?.code || ""}
                onChange={formik.handleChange}
                className="form-control form-select"
                id="code"
                name="organizationInfo.phoneNumber.code"
              >
                <option value="">Select Country Code</option>
                {countryCodes.map((code, index) => (
                  <option key={index} value={code.Code}>
                    {code.Name} {code.NoCode_}
                  </option>
                ))}
              </select>
            </FormItem>
          </div>

          <div className="col-sm-6 mb-3">
            <FormItem
              error={formik.errors.organizationInfo?.phoneNumber?.number}
            >
              <input
                disabled={isLoading}
                type="text"
                id="phoneNumber"
                name="organizationInfo.phoneNumber.number"
                className="form-control"
                placeholder="Phone Number"
                aria-label="phoneNumber"
                value={formik.values.organizationInfo.phoneNumber?.number || ""}
                onChange={formik.handleChange}
              />
            </FormItem>
          </div>

          {/* Country Selection */}
          <div className="col-sm-6 mb-3">
            <FormItem error={formik.errors.country}>
              <select
                disabled={isLoading}
                value={formik.values.country}
                onChange={(e) => {
                  formik.handleChange(e);
                  setSelectedCountry(e.target.value);
                }}
                className="form-control form-select"
                id="country"
                name="organizationInfo.address.country"
              >
                <option value="">Select Country</option>
                {countries.map((country, index) => (
                  <option key={index} value={country}>
                    {country}
                  </option>
                ))}
              </select>
            </FormItem>
          </div>

          {/* State Selection */}
          <div className="col-sm-6 mb-3">
            <FormItem error={formik.errors.state}>
              <select
                disabled={isLoading || states.length === 0}
                value={formik.values.state}
                onChange={(e) => {
                  formik.handleChange(e);
                  setSelectedState(e.target.value);
                }}
                className="form-control form-select"
                id="state"
                name="organizationInfo.address.state"
              >
                <option value="">Select State</option>
                {states.map((state, index) => (
                  <option key={index} value={state}>
                    {state}
                  </option>
                ))}
              </select>
            </FormItem>
          </div>

          {/* City Selection */}
          <div className="col-sm-6 mb-3">
            <FormItem error={formik.errors.city}>
              <select
                disabled={isLoading || cities.length === 0}
                value={formik.values.city}
                onChange={formik.handleChange}
                className="form-control form-select"
                id="city"
                name="organizationInfo.address.city"
              >
                <option value="">Select City</option>
                {cities.map((city, index) => (
                  <option key={index} value={city}>
                    {city}
                  </option>
                ))}
              </select>
            </FormItem>
          </div>

          <div className="col-sm-6 mb-3">
            <FormItem error={formik.errors.state}>
              <input
                disabled={isLoading}
                type="text"
                id="street"
                name="organizationInfo.address.street"
                className="form-control"
                placeholder="Street"
                value={formik.values.organizationInfo.address.street}
                onChange={formik.handleChange}
              />
            </FormItem>
          </div>
          <div className="col-sm-6 mb-3">
            <FormItem error={formik.errors.zipCode}>
              <input
                disabled={isLoading}
                type="text"
                id="zipCode"
                name="organizationInfo.address.zipCode"
                className="form-control"
                placeholder="Zip Code"
                value={formik.values.organizationInfo.address.zipCode}
                onChange={formik.handleChange}
              />
            </FormItem>
          </div>

          {/* Primary Admin Fields */}
          <label className="mb-3">Primary Admin Information</label>
          {/* Admin First Name */}
          <div className="col-sm-6 mb-3">
            <FormItem error={formik.errors.primaryAdmin?.firstName}>
              <input
                disabled={isLoading}
                type="text"
                id="primaryAdmin.firstName"
                name="primaryAdmin.firstName"
                className="form-control"
                placeholder="Admin First Name"
                value={formik.values.primaryAdmin.firstName}
                onChange={formik.handleChange}
              />
            </FormItem>
          </div>

          {/* Admin Last Name */}
          <div className="col-sm-6 mb-3">
            <FormItem error={formik.errors.primaryAdmin?.lastName}>
              <input
                disabled={isLoading}
                type="text"
                id="primaryAdmin.lastName"
                name="primaryAdmin.lastName"
                className="form-control"
                placeholder="Admin Last Name"
                value={formik.values.primaryAdmin.lastName}
                onChange={formik.handleChange}
              />
            </FormItem>
          </div>

          {/* Admin Phone Code */}
          <div className="col-sm-6 mb-3">
            <FormItem error={formik.errors.primaryAdmin?.phoneNumber?.code}>
              <select
                disabled={isLoading}
                value={formik.values.primaryAdmin.phoneNumber.code}
                onChange={formik.handleChange}
                className="form-control"
                id="primaryAdmin.phoneNumber.code"
                name="primaryAdmin.phoneNumber.code"
              >
                <option value="">Select Code</option>
                {countryCodes.map((code, index) => (
                  <option key={index} value={code.Code}>
                    {code.Name} {code.NoCode_}
                  </option>
                ))}
              </select>
            </FormItem>
          </div>

          {/* Admin Phone Number */}
          <div className="col-sm-6 mb-3">
            <FormItem error={formik.errors.primaryAdmin?.phoneNumber?.number}>
              <input
                disabled={isLoading}
                type="text"
                id="primaryAdmin.phoneNumber.number"
                name="primaryAdmin.phoneNumber.number"
                className="form-control"
                placeholder="Admin Phone Number"
                value={formik.values.primaryAdmin.phoneNumber.number}
                onChange={formik.handleChange}
              />
            </FormItem>
          </div>

          {/* Admin Email */}
          <div className="col-sm-6 mb-3">
            <FormItem error={formik.errors.primaryAdmin?.emailAddress}>
              <input
                disabled={isLoading}
                type="email"
                id="primaryAdmin.emailAddress"
                name="primaryAdmin.emailAddress"
                className="form-control"
                placeholder="Admin Email Address"
                value={formik.values.primaryAdmin.emailAddress}
                onChange={formik.handleChange}
              />
            </FormItem>
          </div>

          <div className="col-sm-6 mb-3">
            <FormItem error={formik.errors.websiteUrl}>
              <input
                disabled={isLoading}
                type="url"
                id="websiteUrl"
                name="organizationInfo.websiteUrl"
                className="form-control"
                placeholder="Website URL"
                value={formik.values.organizationInfo.websiteUrl}
                onChange={formik.handleChange}
              />
            </FormItem>
          </div>
          {/* Facebook URL */}
          <div className="col-sm-6 mb-3">
            <FormItem error={formik.errors.facebookUrl}>
              <input
                disabled={isLoading}
                type="url"
                id="facebookUrl"
                name="organizationInfo.facebookUrl"
                className="form-control"
                placeholder="Facebook URL"
                value={formik.values.organizationInfo.facebookUrl}
                onChange={formik.handleChange}
              />
            </FormItem>
          </div>
          {/* Instagram URL */}
          <div className="col-sm-6 mb-3">
            <FormItem error={formik.errors.instagramUrl}>
              <input
                disabled={isLoading}
                type="url"
                id="instagramUrl"
                name="organizationInfo.instagramUrl"
                className="form-control"
                placeholder="Instagram URL"
                value={formik.values.organizationInfo.instagramUrl}
                onChange={formik.handleChange}
              />
            </FormItem>
          </div>

          <div className="col-sm-6 mb-3">
            <FormItem error={formik.errors.youtubeUrl}>
              <input
                disabled={isLoading}
                type="url"
                id="youtubeUrl"
                name="organizationInfo.youtubeUrl"
                className="form-control"
                placeholder="YouTube URL"
                value={formik.values.organizationInfo.youtubeUrl}
                onChange={formik.handleChange}
              />
            </FormItem>
          </div>

          <div className="col-12 text-center">
            <button
              disabled={isLoading}
              style={{ width: "90%" }}
              type="submit"
              className="btn btn-primary mt-4 w-80 p-3"
            >
              {isLoading ? "Sending..." : "Get Started"}
              <i
                style={{ marginLeft: "12px" }}
                className="fas fa-arrow-right fa-lg"
              ></i>
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default FormItems;
