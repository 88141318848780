import React from 'react';

const OnboardingHeader = () => {
  return (
    <>
    <section
          className='page-header position-relative overflow-hidden ptb-100 bg-dark'
        >
          <div className='container'>
            <div>
              <div className='col-lg-8 col-md-12'>
                <h1>Onboarding</h1>
              </div>
            </div>
          </div>
        </section>
    </>
  );
};

export default OnboardingHeader;
