import React from 'react';
import PageMeta from '../components/common/PageMeta';
import Footer from '../layout/Footer/Footer';
import Navbar from '../layout/Header/Navbar';
import Layout from '../layout/Layout';
import DownloadHeader from '../components/Download/DownloadHeader';
import Download from '../components/Download/Download';

const DownloadPage = () => {
  return (
    <Layout>
      <PageMeta title='Download' />
      <Navbar />
      <div>
     <DownloadHeader />
      </div>
      <Download paddingTop='ptb-100'/>
      <Footer />
    </Layout>
  );
};

export default DownloadPage;
