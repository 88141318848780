import axios from "axios";
import React, { useEffect, useState } from "react";
import base_url from "../../api/config";
const Download = ({ paddingTop }) => {
  const [apps, setApps] = useState([]);
  const [error, setError] = useState(null);
  const [expandedAppIndex, setExpandedAppIndex] = useState(null);
  const [copiedIndex, setCopiedIndex] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${base_url}updates/service.json`);
        setApps(response.data);
      } catch (err) {
        setError(err.message);
      }
    };

    fetchData();
  }, []);

  const toggleShowMore = (index) => {
    setExpandedAppIndex(expandedAppIndex === index ? null : index);
  };

  const handleCopySHA = (sha256, index) => {
    navigator.clipboard.writeText(sha256).then(() => {
      setCopiedIndex(index); // Set copied state to show feedback
      setTimeout(() => setCopiedIndex(null), 2000); // Remove feedback after 2 seconds
    });
  };

  return (
    <>
      <section className={`${paddingTop}`}>
        <div className="container">
          <h2 className="text-center">
            Thank you for your interest in our service
          </h2>
          <p className="text-center fs-4">
            You can download any of our Apps here
          </p>

          <div className="download-container bg-light shadow-lg rounded p-5">
            {error ? (
              <p className="text-danger">Error: {error}</p>
            ) : apps.length > 0 ? (
              <div className="row">
                {apps.map((app, index) => (
                  <div key={index} className="col-md-6 col-lg-4 mt-4">
                    <div className="app-item p-3 border rounded shadow-sm h-100">
                      <a href={app.AccessLink} download>
                        <div className="d-flex flex-column justify-content-center align-items-center text-center">
                          <div className="download-icon">
                            <i
                              style={{ color: "#bfbfbf" }}
                              className="fa-solid fa-download fa-4x"
                            ></i>
                          </div>
                          <p
                            style={{ whiteSpace: "nowrap" }}
                            className="btn btn-primary"
                          >
                            Access Link
                          </p>
                        </div>
                      </a>

                      <div className="file-info">
                        <div className="info-section">
                          <h5 className="info-title">Application Name</h5>
                          <p className="info-text">{app.ApplicationName}</p>
                        </div>

                        <div className="info-section">
                          <h5 className="info-title">Platform</h5>
                          <p className="info-text">{app.Platform}</p>
                        </div>
                        {app.SHA256 && (
                          <div className="info-section">
                            <h5 className="info-title">SHA256</h5>
                            <p
                              className="info-text sha-text"
                              title={app.SHA256}
                            >
                              {copiedIndex === index && (
                                <span className="copied-text">Copied!</span>
                              )}
                              <span
                                className="sha-value"
                                onClick={() => handleCopySHA(app.SHA256, index)}
                                style={{ cursor: "pointer" }}
                              >
                                {app.SHA256}
                              </span>
                              {/* Show "Copied!" message if copied */}
                            </p>
                          </div>
                        )}

                        <div className="info-section">
                          <h5 className="info-title">Description</h5>
                          <p
                            className={`info-text ${
                              expandedAppIndex === index ? "" : "truncate"
                            }`}
                          >
                            {app.Description}
                          </p>
                          <button
                            className="btn-show-more"
                            onClick={() => toggleShowMore(index)}
                          >
                            {expandedAppIndex === index
                              ? "Show Less"
                              : "Show More"}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <p>Loading...</p>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default Download;
