import React from 'react';
import PageMeta from '../components/common/PageMeta';
import RequestDemoForm from '../components/contact/RequestDemoForm';
import RequestDemoHeader from '../components/RequestDemoHeader';
import Footer from '../layout/Footer/Footer';
import Navbar from '../layout/Header/Navbar';
import Layout from '../layout/Layout';

const RequestDemo = () => {
return (
<Layout>
<PageMeta title='Request demo' />
<Navbar posAbsolute />
<RequestDemoHeader />
<section className='ptb-120'>
<div className='container'>
<div className='row align-items-center justify-content-between'>
<RequestDemoForm />
<div className='col-xl-5 col-lg-5 col-md-12 order-1 order-lg-0'>
<div className='mt-5'>
  <h2>Request personalized demo of iRegisterkids</h2>
  <p>
  We look forward to showing you how iRegisterkids system can help you seamlessly check-in/out both kids and staff.
  </p>
</div>
</div>
</div>
</div>
</section>
<Footer />
</Layout>
  );
};

export default RequestDemo;
