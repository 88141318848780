import React from 'react';
import Fade from 'react-reveal/Fade';
import {url} from '../../api/config';

const DownlaodApp = () => {
  return (
    <>
      <section
        className='app-two-cta ptb-120 text-white'
        style={{ background: "url('assets/img/banner_bg.png')" }}
      >
        <div className='container'>
          <div className='row align-items-center justify-content-lg-between'>
            <div className='col-lg-6 col-xl-6'>
              <div className='app-two-mockup position-relative text-center pe-5'>
                <img
                  src='assets/img/app-img.png'
                  alt='App mockup'
                  className='img-fluid position-relative z-5'
                />
              </div>
            </div>
            <div className='col-lg-6 col-xl-6 col-md-10'>
              <div className='app-two-cta-right px-md-0 pt-5 pt-md-0'>
                <div className='section-heading text-white'>
                  <h2 className='text-white'>
                  Running organization(s) that put kids care as utmost priority?
                  </h2>
                  <p>
                  Download iRegisterKids Kiosk Apps Now
                  </p>
                </div>
                <div className='cta-count'>
                </div>

               <div className='mt-3'>
                <ul className='list-unstyled text-center'>
                 
                 {/*
                <Fade up>
                 <li className='d-inline-block me-2 mb-lg-0 mb-3'>
                  <a href='https://play.google.com/store/apps/details?id=com.igeeksng.iregisterkidstab' className='align-items-center text-decoration-none' target="_blank" rel="noopener noreferrer">
                  <img src='assets/img/playstore.svg' alt='Google Play' />
                  </a>
                  </li>
                  </Fade>
                  
                  <Fade up>
                  <li className='d-inline-block me-2 mb-lg-0 mb-3'>
                  <a href='https://apps.apple.com/us/app/iregister-kids-self-check/id1555331899' target="_blank" rel="noopener noreferrer">
                  <img src='assets/img/appstore.svg' alt='App store' />
                  </a>
                  </li>
                  </Fade>
  */}
{/* 
                  <Fade up>
                  <li className='d-inline-block me-2 mb-lg-0'>
                  <a href={url.kiosk} target="_blank" rel="noopener noreferrer">
                  <img style={{borderRadius: '9px'}} src='assets/img/windows-6.png' alt='Windows store' />
                  </a>
                  </li>
                  </Fade> */}

                  <Fade up>
                  <li className='d-inline-block me-2 mt-2 mb-lg-0'>
                  <a className="btn btn-warning p-3" href='/download' target="_blank" rel="noopener noreferrer">
                       Download Our Apps
                      </a>
                      </li>
                  </Fade>
                </ul>
              </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default DownlaodApp;
