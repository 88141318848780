import React, { useState, useEffect } from "react";
import axios from "axios";
import { useFormik } from "formik";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as yup from "yup";
import FormItems from "./FormItems";
import base_url from "../../api/config";

const defaultForm = {
  organizationInfo: {
    name: "",
    emailAddress: "",
    phoneNumber: {
      number: 0,
      code: "",
    },
    address: {
      street: "",
      city: "",
      state: "",
      zipCode: "",
      country: "",
    },
    websiteUrl: "",
    facebookUrl: "",
    instagramUrl: "",
    youtubeUrl: "",
  },
  primaryAdmin: {
    firstName: "",
    lastName: "",
    emailAddress: "",
    phoneNumber: {
      number: "",
      code: "",
    },
  },
};

// Validation schema using Yup
const validationSchema = yup.object({
  organizationInfo: yup.object({
    name: yup.string().required("Organization name is required"),
    emailAddress: yup.string().email().required("Email address is required"),
    phoneNumber: yup.object({
      number: yup.string().required("Phone number is required"),
       code: yup.string().required("First name is required"),
    }),
  }),
  primaryAdmin: yup.object({
    firstName: yup.string().required("First name is required"),
    lastName: yup.string().required("Last name is required"),
    emailAddress: yup.string().email().required("Admin email is required"),
    phoneNumber: yup.object({
      number: yup.string().required("Phone number is required"),
      code: yup.string().required("Country code is required"),
    }),
  }),
  websiteUrl: yup.string().url("Must be a valid URL"),
  facebookUrl: yup.string().url("Must be a valid URL"),
  instagramUrl: yup.string().url("Must be a valid URL"),
  youtubeUrl: yup.string().url("Must be a valid URL"),
});

const OnboardingForm = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [countryCodes, setCountryCodes] = useState([]);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");


  useEffect(() => {
    const fetchCountryCodes = async () => {
      try {
        const result = await axios.get(`${base_url}resources/detailed_country_code`);
        setCountryCodes(result.data);
      } catch (error) {
        console.error("Error fetching country codes", error);
        toast.error("Failed to fetch country codes.");
      }
    };

    fetchCountryCodes();
  }, []);

  // Fetch countries and states
  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const result = await axios.get(`${base_url}resources/country_states_cities`);
        setCountries(result.data);
      } catch (error) {
        console.error("Error fetching countries", error);
        toast.error("Failed to fetch countries.");
      }
    };

    fetchCountries();
  }, []);

  // Fetch states based on the selected country
  useEffect(() => {
    if (selectedCountry) {
      const fetchStates = async () => {
        try {
          const result = await axios.get(
            `${base_url}resources/country_states_cities?country=${selectedCountry}`
          );
          setStates(result.data);
        } catch (error) {
          toast.error("Failed to fetch states for the selected country.");
        }
      };
      fetchStates();
    }
  }, [selectedCountry]);

  useEffect(() => {
    if (selectedCountry && selectedState) {
      const fetchCities = async () => {
        try {
          const result = await axios.get(
            `${base_url}resources/country_states_cities?country=${selectedCountry}&state=${selectedState}`
          );
          setCities(result.data);
        } catch (error) {
          toast.error("Failed to fetch cities for the selected state.");
        }
      };
      fetchCities();
    }
  }, [selectedCountry, selectedState]);

  const handleSubmit = async (data, formikBag) => {
    try {
      setIsLoading(true);
      const config = {
        headers: {
          "content-type": "application/json",
        },
      };
      const result = await axios.post(
        `${base_url}onboarding/add`,
        data,
        config
      );
      toast.success(result.data.message);
      formikBag.resetForm();
    } catch (error) {
      toast.error(
        error.response && error.response.data?.message
          ? error.response.data.message
          : error.message || "Error submitting form"
      );
    } finally {
      setIsLoading(false);
    }
  };

  const formik = useFormik({
    validationSchema,
    initialValues: defaultForm,
    onSubmit: handleSubmit,
  });

  return (
    <>
       <ToastContainer 
    position="top-center" 
    autoClose={15000} 
    hideProgressBar={false} 
    newestOnTop={false} 
    closeOnClick 
    rtl={false} 
    pauseOnFocusLoss 
    draggable 
    pauseOnHover 
    style={{ zIndex: 9999 }} 
  />
      <div className="container">
        <div className="d-flex inner-container">
          {/* Left Section */}
          <div className="left-section p-5 text-white d-flex flex-column justify-content-center align-items-center">
            <img
              src="assets/img/iregisterkids-white.png"
              alt="logo"
              className="img-fluid logo-white mb-4"
            />
            <h3 className="mb-4 text-white">Onboard your company</h3>
            <p className="mb-5">
              Let's help you setup your platform in minutes.
            </p>
           
          </div>
          {/* Right section */}
          <div className="p-5">
            <div className="progress-bar">
              <div className="progress-bar-dots">
                <span className="active"></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span className="active"></span>
                <span></span>
                <span></span>
                <span></span>
                <span className="active"></span>
              </div>
            </div>
            <h3 className="fw-medium mt-8">Let's get started</h3>
            <div className="row">
              <FormItems
                formik={formik}
                isLoading={isLoading}
                countryCodes={countryCodes}
                countries={countries}
                setSelectedCountry={setSelectedCountry}
                states={states}
                cities={cities}
                setSelectedState={setSelectedState}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OnboardingForm;
